import React, {useState, useEffect} from 'react';
import { getQueryString } from './getQueryString';

const MobileCheckResult = () => {
    useEffect(()=>{

        let responseObj = {'apiToken': getQueryString('apiToken'), 'certNum': getQueryString('certNum')};
        console.log(responseObj);

        if(responseObj.apiToken && responseObj.certNum){
            window.opener.document.querySelector('#apiToken').value = responseObj.apiToken;
            window.opener.document.querySelector('#certNum').value = responseObj.certNum;
            window.opener.document.querySelector('#apiToken').click();
        }else{
            alert('토큰값 또는 요청번호가 비정상입니다.');
        }
        window.close();

    },[])

    return(
        <></>
    )
}

export default MobileCheckResult;