import { t } from 'i18next';
import React, { useEffect, useState, useRef } from 'react';
import DaumPost from '../../Util/DaumPost';
import Loading from '../../Util/Loading';
import { changeUserInfo, getUserInfor, getUserAuthParameter, openKmcert, getUserNameAndPhoneByEncData, checkEmail } from '../../Util/reqApi';
import ModalTerm from "../../Layouts/Modal/modalTerm";
import axios from "axios";
import {renderToString} from "react-dom/server";


const ChangeProfile = () => {
    const [tr_cert, set_tr_cert]  = useState();
    const [tr_url, set_tr_url ] = useState();
    const [tr_ver, set_tr_ver] = useState();

    const [cert, setCert] = useState(false);

    const [userInfor, setUserInfor] = useState('');
    const [post, setPost] = useState('');
    const [postModal, setPostModal] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [companyRegNumber, setCompanyRegNumber] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [managerName, setManagerName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [ceoName, setCeoName] = useState('');
    const [reqFiles, setReqFiles] = useState();
    const [businessType, setBusinessType] = useState('');
    const [businessItem, setBusinessItem] =useState('');
    const [recvNewsletter, setRecvNewsletter] = useState();
    const [recvNewsletterDate, setRecvNewsletterDate] = useState();
    const [recvNewsletterOrg, setRecvNewsletterOrg] = useState(false);
    const [recvSms, setRecvSms] = useState();
    const [recvSmsOrg, setRecvSmsOrg] = useState(false);
    const [recvSmsDate, setRecvSmsDate] = useState();
    const [consentUserInfoTerm, setConsentUserInfoTerm] = useState();
    const [consentUserInfoTermDate, setConsentUserInfoTermDate] = useState();
    const [consentUserInfoTermOrg, setConsentUserInfoTermOrg] = useState(false);
    const [consent3rdInfo, setConsent3rdInfo] = useState();
    const [consent3rdInfoDate, setConsent3rdInfoDate] = useState();
    const [consent3rdInfoOrg, setConsent3rdInfoOrg] = useState(false);
    const [overseasTransferOfPInfo, setOverseasTransferOfPInfo] = useState();
    const [overseasTransferOfPInfoDate, setOverseasTransferOfPInfoDate] = useState();
    const [overseasTransferOfPInfoOrg, setOverseasTransferOfPInfoOrg] = useState(false);
    const [privacyAgr, setPrivacyAgr] = useState(true);

    const [businessRegFile, setBusinessRegFile] = useState();

    const reqKMCISForm = useRef();
    const resKMCISForm = useRef();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    const [simpleTextModal, setSimpleTextModal] = useState(false);
    const [simpleModalContent, setSimpleModalContent] = useState('');
    const [simpleModalTitle, setSimpleModalTitle] = useState('');

    const getTerm = async (type) => {
        setModalContent();

        setModalType(type)
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=${type}`);
        setModalContent(result.data.data);
    }
    const OptionTerm = () => {
        return(
            <>
                <p>■회원관리 목적의 개인 정보 수집 이용 동의(선택)</p>

                <p>&nbsp;</p>

                <p><b>&lt;수집하는 개인정보 항목 및 이용 목적&gt;</b></p>

                <p>운영자가 수집하는 개인정보 항목 및 이용 목적은 다음과 같습니다. 회원은 개인정보 수집 동의에 대해 거부하실 수 있으며, 거부 시에도 API PLEX 서비스 이용에 제한은 없습니다.</p>

                <p>&nbsp;</p>

                <p><b>① 개인정보 항목</b></p>

                <table border="1" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                        <td valign="top" width="301" style={{textAlign: "center", backgroundColor: "lightgray"}}>
                            <p>목적</p>
                        </td>
                        <td valign="top" width="301" style={{textAlign: "center", backgroundColor: "lightgray"}}>
                            <p>수집하는 개인정보 항목</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" width="301">
                            <p>회원관리</p>
                        </td>
                        <td valign="top" width="301">
                            <p>메일서비스 수신여부, 문자서비스 수신여부</p>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <p>&nbsp;</p>

                <p><b>② 이용하는 목적</b></p>

                <p>가. <u>서비스 제공 및 운영</u></p>

                <p>: 서비스 개선 및 신규서비스 개발, 신규 서비스 개발 및 맞춤 서비스 제공, 기능개선, 인구통계학적 특성에 따른 서비스 제공, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 목적</p>

                <p>나. <u>이용자 관리</u></p>

                <p>: 이용신청 확인, 분쟁 조정을 위한 기록보전, 불만처리 등 민원처리, 고지사항 전달, 이용자 요청에 따른 이용내역(이용정보) 제공, 서비스 이용에 대한 통계</p>

                <p>&nbsp;</p>

                <p><b>&lt;개인정보의 보유 및 이용기간&gt;</b></p>

                <p>1. 운영자는 아래와 같이 법령에 따른 개인정보 보유 및 이용기간 또는 이용자로부터 개인정보를 수집 시에 동의 받은 개인정보 보유 및 이용기간 내에서 개인정보를 보유하고 이용합니다.</p>

                <p style={{fontSize:"16px"}}><b>① 서비스 이용기간(신청일 ~ 해지일)</b></p>

                <p style={{fontSize:"16px"}}><b>② 요금정산, 요금 과오납 등 분쟁 대비를 위해 해지 또는 요금완납 후 6개월까지</b></p>

                <p style={{fontSize:"16px"}}><b>③ 요금관련 분쟁 등 서비스 이용과 관련된 민원/분쟁이 계속될 경우에는 민원/분쟁 해결 시까지 보유(단, 관계 법령에 특별한 규정이 있을 경우 관련 법령에 따라 보관)</b></p>

                <p>&nbsp;</p>

                <p>상법, 국세기본법, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는 경우 관계법령에서 규정한 기간 동안 이용자의 거래내역과 최소한의 기본정보를 보유합니다.</p>

                <p>&nbsp;</p>
            </>
        )
    }
    const AdTerm = () => {
        return(
            <>
                <p>■광고성 정보 수신 동의 (선택)</p>

                <p>&nbsp;</p>

                <p><b>&lt;수집하는 광고성 정보 항목 및 이용 목적&gt;</b></p>

                <p>광고성 정보 전송을 위한 개인정보수집 항목은 다음과 같습니다. 회원은 광고성 정보 수신 및 광고성을 위한 개인정보 수집이용을 거부하실 수 있으며, 거부 시에도 API PLEX 서비스 이용에 제한은 없습니다.</p>

                <p>&nbsp;</p>

                <p><b>① 개인정보 항목</b></p>

                <table border="1" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                        <td valign="top" width="301" style={{textAlign: "center", backgroundColor: "lightgray"}}>
                            <p>목적</p>
                        </td>
                        <td valign="top" width="301" style={{textAlign: "center", backgroundColor: "lightgray"}}>
                            <p>수집하는 개인정보 항목</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" width="301">
                            <p>광고성 정보 수신</p>
                        </td>
                        <td valign="top" width="301">
                            <p>이메일, SMS 서비스</p>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <p>&nbsp;</p>

                <p><b>② 이용하는 목적</b></p>

                <p><u>이벤트 운영 및 혜택/광고성 정보 제공</u></p>

                <p>: 회원에게 유용한 각종 이벤트나 혜택, 광고성 정보의 제공, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계, 신규 서비스 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 목적</p>

                <p>&nbsp;</p>

                <p><b>&lt;개인정보의 보유 및 이용기간&gt;</b></p>

                <p style={{fontSize:"16px"}}><b>회원탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우. 이용기간이 종료한 경우 개인정보를 지체없이 파기합니다. 단, 상법, 국세기본법, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는 경우 법령에서 규정한 보존기간 동안 이용자의 거래내역과 최소한의 기본정보를 보유합니다.</b></p>

                <p>&nbsp;</p>
            </>
        )
    }

    useEffect(()=>{
        getUserInfor().then(result => {
            setUserInfor(result || '');
            setCompanyName(result.companyName || '');
            setCompanyRegNumber(result.companyRegNumber || '');
            setPost(result.companyAddress || '');
            setCompanyAddress(result.companyAddressDetail || '')
            setManagerName(result.managerName || '');
            setPhone(result.phone || '');
            setEmail(result.email || '');
            setCeoName(result.ceoName || '');
            setBusinessType(result.businessType || '');
            setBusinessItem(result.businessItem || '');

            setRecvSms(result.recvSms);
            setRecvSmsOrg(result.recvSms);
            setRecvSmsDate(formatLocalDateTime(result.recvSmsDate));

            setRecvNewsletter(result.recvNewsletter);
            setRecvNewsletterOrg(result.recvNewsletter);
            setRecvNewsletterDate(formatLocalDateTime(result.recvNewsletterDate));

            setConsentUserInfoTerm(result.consentUserInfoTerm);
            setConsentUserInfoTermOrg(result.consentUserInfoTerm);
            setConsentUserInfoTermDate(formatLocalDateTime(result.consentUserInfoTermDate));

            setConsent3rdInfo(result.consent3rdInfo);
            setConsent3rdInfoOrg(result.consent3rdInfo);
            setConsent3rdInfoDate(formatLocalDateTime(result.consent3rdInfoDate));

            setOverseasTransferOfPInfo(result.overseasTransferOfPInfo);
            setOverseasTransferOfPInfoOrg(result.overseasTransferOfPInfo);
            setOverseasTransferOfPInfoDate(formatLocalDateTime(result.overseasTransferOfPInfoDate));

            setPrivacyAgr(result.privacyAgr || true)
            setBusinessRegFile(result.businessRegFile || '')
        });
    },[])

    useEffect(()=>{
        if(cert && userInfor){
            getUserNameAndPhoneByEncData(document.querySelector('#certNum')?.value, document.querySelector('#apiToken')?.value).then(
                result => {
                    setManagerName(result?.data.name);
                    setPhone(result?.data.phoneNo);
                }
            )
        }
    },[cert])

    function formatLocalDateTime(localDateTimeArray) {
        if(!localDateTimeArray) return;
        if (!localDateTimeArray || localDateTimeArray.length < 4) {
            console.error('배열의 길이가 충분하지 않습니다.');
            return '';
        }

        const year = localDateTimeArray[0];
        const month = (localDateTimeArray[1]).toString().padStart(2, '0');
        const day = localDateTimeArray[2].toString().padStart(2, '0');
        const hours = localDateTimeArray[3].toString().padStart(2, '0');
        const minutes = localDateTimeArray.length > 4 ? localDateTimeArray[4].toString().padStart(2, '0') : '00';
        const seconds = localDateTimeArray.length > 5 ? localDateTimeArray[5].toString().padStart(2, '0') : '00';

        return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds}`;
    }

    if(userInfor){
        return(
            <div className="acc_section">
                <div className="script_b">
                    회원님의 정보를 수정할 수 있습니다.<br />
                    * 정식 계정 전환 : 최초 가입자의 경우 사업자 정보를 등록 후 [저장] 버튼을 눌러주십시오. 운영자 승인 후 "정식계정"으로 전환됩니다.<br />
                    * 사업자 정보 수정 : "정식계정" 전환 후 회사명, 대표자명 등의 사업자 정보 수정의 경우, 운영자의 승인이 필요합니다. <br />
                    * 연동 테스트를 희망하는 분은 1:1 문의하기에 문의해주세요.
                </div>
                <div className="table_area">
                    <table className="table_mypageA">
                        <colgroup>
                            <col width="200px" />
                            <col width="*" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>계정상태</th>
                                <td>{userInfor.userStatus.statusName}</td>
                            </tr>
                            <tr>
                                <th>로그인 아이디</th>
                                <td>{userInfor.userId}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table_area">
                    <table className="table_mypageB">
                        <colgroup>
                            <col width="200px" />
                            <col width="*" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>회사명</th>
                                <td>
                                    <input type="text" style={{width:"400px"}} placeholder="회사명" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}} required/>
                                </td>
                            </tr>
                            <tr>
                                <th>사업자 등록번호</th>
                                <td>                                    
                                    <input type="text" style={{width:"400px"}} placeholder="사업자 등록번호" value={companyRegNumber} onChange={(e)=>{setCompanyRegNumber(e.target.value)}} required/>
                                </td>
                            </tr>
                            <tr>
                                <th>대표자명</th>
                                <td>
                                    <input type="text" style={{width:"400px"}} placeholder="" value={ceoName} onChange={(e)=>setCeoName(e.target.value)} required/>
                                </td>
                            </tr>
                            <tr>
                                <th>업태/종목</th>
                                <td>
                                    <input type="text" style={{width:"190px"}} placeholder="" value={businessType} onChange={(e)=>{setBusinessType(e.target.value)}} required/>
                                    <span style={{width:"20px",display:"inline-block",textAlign:"center"}} >/</span>
                                    <input type="text" style={{width:"190px"}} placeholder="" value={businessItem} onChange={(e)=>{setBusinessItem(e.target.value)}} required/>
                                </td>
                            </tr>
                            <tr>
                                <th rowSpan="2">사업자 주소</th>
                                <td>
                                    <input type="text" style={{width:"300px"}} placeholder="주소" value={post} readOnly={true} required/>
                                    <button
                                        className="btn_sm2"
                                        style={{width:"96px"}}
                                        onClick={()=>{setPostModal(true)}}
                                    >주소검색</button>
                                    <div style={{width:"400px"}}>
                                    { postModal && <DaumPost setPostModal={setPostModal} setPost={setPost} /> }
                                    </div>
                                </td>                                
                            </tr>
                            <tr>
                                <td>
                                    <input type="text" style={{width:"300px"}} placeholder="상세주소" value={companyAddress} onChange={(e)=>{setCompanyAddress(e.target.value)}} required/>
                                </td>
                            </tr>
                            <tr>
                                <th>사업자등록증</th>
                                <td>
                                    <FileForm setReqFiles={setReqFiles}></FileForm>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{height:"30px"}} ></td>
                            </tr>
                            <tr>
                                <th>담당자 이름</th>
                                <td><input type="text" style={{width:"400px"}} placeholder="이름" value={managerName} onChange={(e)=>{setManagerName(e.target.value)}} readOnly={true} required/></td>
                            </tr>
                            <tr>
                                <th rowSpan="1">휴대폰 번호</th>
                                <td>
                                    <input type="text" style={{width:"300px"}} placeholder="" value={phone} onChange={(e)=>{setPhone(e.target.value)}} readOnly={true} required/>
                                    <button className="btn_sm3" style={{width:"96px"}}
                                        onClick={()=>{
                                            setCert(false);
                                            getUserAuthParameter({callbackUrl:`${window.location.origin}/mobileCheckResult`, type:'updateUserInfo'}).then(result => {
                                                set_tr_cert(result.tr_cert);
                                                set_tr_url(result.tr_url);
                                                set_tr_ver(result.tr_ver);
                                                openKmcert(reqKMCISForm.current);
                                            })
                                        }}
                                    >인증요청</button>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td><input type="text" style={{width:"400px"}} placeholder="이메일" value={email} onChange={(e)=>{setEmail(e.target.value)}} required/></td>
                            </tr>

                            <tr>
                                <td colSpan="2" style={{height:"30px"}} ></td>
                            </tr>
                            <tr>
                                <th colSpan="2" style={{fontSize:'18px' ,paddingLeft:'86px',textAlign:'left'}}>광고성 정보 수신 동의(선택)
                                    <button className="viewBtn" onClick={()=>{
                                        setSimpleTextModal(true)
                                        setSimpleModalContent(AdTerm);
                                        setSimpleModalTitle('광고성 정보 수신 동의(선택)')
                                    }}
                                            aria-label='광고성 정보 수신 동의(선택) 보기'
                                    >   보기</button>
                                </th>
                            </tr>
                            <tr>
                                <th>SMS 수신 여부</th>
                                <td>
                                    <input type="radio" id="Grade1" name="recvSms" checked={recvSms ? true : false} onChange={(e)=>{setRecvSms(true);}}/>
                                    <label htmlFor="Grade1"><span></span>수신</label>
                                    <input type="radio" id="Grade2" name="recvSms" checked={!recvSms ? true : false} onChange={(e)=>{setRecvSms(false);}}/>
                                    <label htmlFor="Grade2"><span></span>수신거부</label>
                                    <span style={{marginLeft:"10px"}}>{recvSms != recvSmsOrg ? '' : (recvSms ? (recvSmsDate ? `(수신동의일 : ${recvSmsDate})` : '') : (recvSmsDate ? `(수신거부일 : ${recvSmsDate})` : ''))}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일 수신 여부</th>
                                <td>
                                    <input type="radio" id="Grade3" name="recvNewsletter" value={true} checked={recvNewsletter ? true : false} onChange={(e)=>{setRecvNewsletter(true);}}/>
                                    <label htmlFor="Grade3"><span></span>수신</label>
                                    <input type="radio" id="Grade4" name="recvNewsletter" value={false} checked={!recvNewsletter ? true : false} onChange={(e)=>{setRecvNewsletter(false)}}/>
                                    <label htmlFor="Grade4"><span></span>수신거부</label>
                                    <span style={{marginLeft:"10px"}}>{recvNewsletter != recvNewsletterOrg ? '' : (recvNewsletterOrg ? (recvNewsletterDate ? `(수신동의일 : ${recvNewsletterDate})` : '') : (recvNewsletterDate ? `(수신거부일 : ${recvNewsletterDate})` : ''))}</span>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="2" style={{height:"30px"}} ></td>
                            </tr>
                            <tr>
                                <th colSpan="2" style={{fontSize:'18px' ,paddingLeft:'86px',textAlign:'left'}}>회원관리 목적의 개인 정보 수집 이용 동의(선택)</th>
                            </tr>
                            <tr>
                                <th>동의 여부</th>
                                <td>
                                    <input type="radio" id="Grade5" name="consentUserInfoTerm" value={true} checked={consentUserInfoTerm ? true : false} onChange={(e)=>{setConsentUserInfoTerm(true)}}/>
                                    <label htmlFor="Grade5"><span></span>동의</label>
                                    <input type="radio" id="Grade6" name="consentUserInfoTerm" value={false} checked={!consentUserInfoTerm ? true : false} onChange={(e)=>{setConsentUserInfoTerm(false)}}/>
                                    <label htmlFor="Grade6"><span></span>미동의</label>
                                    <span style={{marginLeft:"10px"}}>{consentUserInfoTerm != consentUserInfoTermOrg ? '' : (consentUserInfoTermOrg ? (consentUserInfoTermDate ? `(동의일 : ${consentUserInfoTermDate})` : '') : (consentUserInfoTermDate ? `(거부일 : ${consentUserInfoTermDate})` : ''))}</span>
                                    <button className="viewBtn" onClick={()=>{
                                        setSimpleTextModal(true)
                                        setSimpleModalContent(OptionTerm)
                                        setSimpleModalTitle('회원관리 목적의 개인 정보 수집 이용 동의(선택)')
                                    }}
                                            aria-label='회원관리 목적의 개인 정보 수집 이용 동의(선택) 보기'
                                    >   보기</button>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="2" style={{height:"30px"}} ></td>
                            </tr>
                            <tr>
                                <th colSpan="2" style={{fontSize:'18px' ,paddingLeft:'86px',textAlign:'left'}}>제 3자 정보 제공 동의(선택)</th>
                            </tr>
                            <tr>
                                <th>동의 여부</th>
                                <td>
                                    <input type="radio" id="Grade7" name="consent3rdInfo" value={true} checked={consent3rdInfo ? true : false} onChange={(e)=>{setConsent3rdInfo(true)}}/>
                                    <label htmlFor="Grade7"><span></span>동의</label>
                                    <input type="radio" id="Grade8" name="consent3rdInfo" value={false} checked={!consent3rdInfo ? true : false} onChange={(e)=>{setConsent3rdInfo(false)}}/>
                                    <label htmlFor="Grade8"><span></span>미동의</label>
                                    <span style={{marginLeft:"10px"}}>{consent3rdInfo != consent3rdInfoOrg ? '' : (consent3rdInfoOrg ? (consent3rdInfoDate ? `(동의일 : ${consent3rdInfoDate})` : '') : (consent3rdInfoDate ? `(거부일 : ${consent3rdInfoDate})` : ''))}</span>
                                    <button className="viewBtn" onClick={() => {getTerm('T'); setModalOpen(true)}} aria-label='제 3자 정보 제공 동의(선택) 보기'>   보기</button>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="2" style={{height:"30px"}} ></td>
                            </tr>
                            <tr>
                                <th colSpan="2" style={{fontSize:'18px' ,paddingLeft:'86px',textAlign:'left'}}>개인정보 국외 이전 동의(선택)</th>
                            </tr>
                            <tr>
                                <th>동의 여부</th>
                                <td>
                                    <input type="radio" id="Grade9" name="overseasTransferOfPInfo" value={true} checked={overseasTransferOfPInfo ? true : false} onChange={(e)=>{setOverseasTransferOfPInfo(true)}}/>
                                    <label htmlFor="Grade9"><span></span>동의</label>
                                    <input type="radio" id="Grade10" name="overseasTransferOfPInfo" value={false} checked={!overseasTransferOfPInfo ? true : false} onChange={(e)=>{setOverseasTransferOfPInfo(false)}}/>
                                    <label htmlFor="Grade10"><span></span>미동의</label>

                                    <span style={{marginLeft:"10px"}}>{overseasTransferOfPInfo != overseasTransferOfPInfoOrg ? '' : (overseasTransferOfPInfoOrg ? (overseasTransferOfPInfoDate ? `(동의일 : ${overseasTransferOfPInfoDate})` : '') : (overseasTransferOfPInfoDate ? `(거부일 : ${overseasTransferOfPInfoDate})` : ''))}</span>
                                    <button className="viewBtn" onClick={() => {getTerm('O'); setModalOpen(true)}} aria-label='개인정보 국외 이전 동의(선택) 보기'>   보기</button>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="2" style={{height:"30px"}} ></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="ip_btn_area">
                    <button style={{width:"200px"}} className="login_1"
                        onClick={()=>{
                            if(!companyName){
                                alert(`회사명은 필수입니다.`)
                                return;
                            }
                            if(!companyRegNumber){
                                alert(`사업자 등록번호는 필수입니다.`)
                                return;
                            }
                            if(!ceoName){
                                alert(`대표자명은 필수입니다.`)
                                return;
                            }
                            if(!businessType || !businessItem){
                                alert(`업태/종목은 필수입니다.`)
                                return;
                            }
                            if(!companyAddress || !companyAddress){
                                alert(`사업자 주소는 필수입니다.`)
                                return;
                            }
                            if(!businessRegFile){
                                if(!reqFiles){
                                    alert(`사업자등록증을 첨부해주세요.`)
                                    return;
                                }
                            }
                            if(!managerName){
                                alert(`담당자 이름은 필수입니다.`)
                                return;
                            }
                            if(!phone){
                                alert(`휴대폰 번호는 필수입니다.`)
                                return;
                            }
                            if(!checkEmail(email)){
                                alert(`이메일 형식이 올바르지 않습니다.`)
                                return;
                            }
                            if(!email){
                                alert(`이메일은 필수입니다.`)
                                return;
                            }

                            changeUserInfo({
                                post : post,
                                companyName : companyName,
                                companyRegNumber : companyRegNumber,
                                companyAddress : post,
                                companyAddressDetail : companyAddress,
                                managerName : managerName,
                                phone : phone,
                                email : email,
                                ceoName : ceoName,
                                businessType : businessType,
                                businessItem : businessItem,
                                recvNewsletter : recvNewsletter,
                                recvSms : recvSms,
                                consentUserInfoTerm : consentUserInfoTerm,
                                consent3rdInfo : consent3rdInfo,
                                overseasTransferOfPInfo : overseasTransferOfPInfo,
                                privacyAgr : privacyAgr,
                                certNum: document.querySelector('#certNum').value,
                                apiToken : document.querySelector('#apiToken').value,
                            }, reqFiles).then(result=> {
                                if(result.code == 2){alert(result.data); return}
                                let consentNoti = "";
                                if(recvSms != recvSmsOrg) consentNoti += ("광고성 정보 수신(SMS) "+(recvSms?"동의":"거부")+"하셨습니다.\n")
                                if(recvNewsletter != recvNewsletterOrg) consentNoti += ("광고성 정보 수신(이메일) "+(recvNewsletter?"동의":"거부")+"하셨습니다.\n")
                                if(consentUserInfoTerm != consentUserInfoTermOrg) consentNoti += ("회원관리 목적의 개인정보 수집 이용 "+(consentUserInfoTerm?"동의":"거부")+"하셨습니다.\n")
                                if(consent3rdInfo != consent3rdInfoOrg) consentNoti += ("제 3자 정보 제공 "+(consent3rdInfo?"동의":"거부")+"하셨습니다.\n")
                                if(overseasTransferOfPInfo != overseasTransferOfPInfoOrg) consentNoti += ("개인정보 국외 이전 "+(consent3rdInfo?"동의":"거부")+"하셨습니다.\n")

                                if(consentNoti != ''){
                                    let formattedTime = new Intl.DateTimeFormat('ko-KR', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        timeZone: 'Asia/Seoul',
                                    }).format(Date.now());
                                    consentNoti += ("(적용일 : "+formattedTime+" )");
                                    alert(consentNoti);
                                }
                                alert('저장되었습니다.')
                            })
                        }}
                    >저장</button>
                </div>
                <form name="reqKMCISForm" method="post" action="#" ref={reqKMCISForm}>
                    <input type="hidden" name="tr_cert" value={tr_cert} />
                    <input type="hidden" name="tr_url"  value={tr_url} />
                    <input type="hidden" name="tr_ver"  value={tr_ver} />
                </form>
                <form name="resKMCISForm" ref={resKMCISForm} className="em_ligin">
                    <input type="hidden" id="apiToken" style={{marginBottom: "20px"}} onClick={()=>{setCert(true)}} />
                    <input type="hidden" id="certNum" />
                </form>
                <ModalTerm modalOpen={modalOpen} setModalOpen={setModalOpen} setModalClose={() => {setModalOpen(false)}} modalContent={modalContent} type={modalType}/>
                <SimpleModal simpleTextModal={simpleTextModal} setSimpleTextModal={setSimpleTextModal} simpleModalContent={simpleModalContent} simpleModalTitle={simpleModalTitle}></SimpleModal>
            </div>
        )
    }else{
        return <Loading />
    }
}

const FileForm = (props) => {
    const {setReqFiles} = props;
    const [files, setFiles] = useState();
    const [viewFiles, setViewFiles] = useState();

    useEffect(()=>{
        setReqFiles(files)
    },[files])

    return(
        <>
            <input
                type="file"
                style={{display:'none'}}
                id="addFile"
                name="addFile"
                multiple="multiple"
                onClick={(e)=>{ e.target.value = '' }}
                onChange={(e)=>{
                    let fileData = new FormData();
                    for(const element of e.target.files){
                        fileData.append('businessRegistration ', element , element.name);
                    }
                    setFiles(fileData)
                    
                    setViewFiles(fileData.getAll('businessRegistration '))
                }}
            />
            <button className="btn_sm4" onClick={()=>{document.querySelector('#addFile').click();}}>{t('support.reqForm07_btn')}</button>
            <div className="file_list">
                {
                    viewFiles && viewFiles.length !== 0 &&
                        viewFiles.map((file,idx)=>{
                            return <FileItem key={idx} idx={idx} files={viewFiles} setReqFiles={setReqFiles} setFiles={setViewFiles}>{file.name}</FileItem>
                        })
                }
            </div>
        </>
    )
}

const FileItem = (props) => {
    const {children, files, setFiles, setReqFiles, idx} = props;
    return(
        <div className={`file ${idx}`}>
            {children}
            <button style={{padding:0}}>
                <img
                    style={{verticalAlign: 'baseline'}}
                    src={`/images/icon_f_close.png`}
                    alt="첨부파일 삭제"
                    onClick={()=>{
                        if(files.length == 1){
                            setFiles();
                            setReqFiles();
                        return}
                        setFiles([...files.slice(0,idx), ...files.slice(idx+1, files.length)])
                    }}
                />
            </button>
        </div>
    )
}

const SimpleModal = (props) => {
    const {simpleModalContent, simpleTextModal, setSimpleTextModal, subAccountData, checkedSubAccount, svcNo, setSubAccountData, simpleModalTitle} = props;
    return(
        <div className="pop_set_b" style={simpleTextModal ? {} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit_t16">{simpleModalTitle}</div>
                            <div className="rclose" onClick={()=>{setSimpleTextModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div className="text_policy _guide_area" tabIndex={0}>
                            <div dangerouslySetInnerHTML={{__html: renderToString(simpleModalContent).replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}></div>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_as"
                                    onClick={()=>{
                                        setSimpleTextModal(false)
                                    }}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeProfile;