import React, {useState, useEffect, useRef} from 'react';
import Loading from '../../Util/Loading';
import { useParams } from 'react-router-dom';
import { getSubAccount, getSenderNo, reqAdminCertify, reqSMSAdminCertify, checkAdminCheckCallNumber, getUserAuthParameter, getUserNameAndPhoneByEncData, openKmcert } from '../../Util/reqApi';

const ServiceConsoleTab03 = () => {
    const [senderList, setSenderList] = useState();
    const [subAccount, setSubAccount] = useState();
    const [reqModal, setReqModal] = useState(false);
    const [SMSModal, setSMSModal] = useState(false);
    const [adminModal, setAdminModal] = useState(false);
    const [reqFiles01, setReqFiles01] = useState();
    const [reqFiles02, setReqFiles02] = useState();
    const [reqFiles03, setReqFiles03] = useState();
    const [reqFiles04, setReqFiles04] = useState();

    const [smsCheck, setSmsCheck] = useState(false);
    const [smsNumber, setSmsNumber] = useState('');
    const [phone, setPhone] =useState('');

    const [tr_cert, set_tr_cert]  = useState();
    const [tr_url, set_tr_url ] = useState();
    const [tr_ver, set_tr_ver] = useState();

    const [smsCert, setSmsCert] = useState(false);

    const { api } = useParams();

    const reqKMCISForm = useRef();

    useEffect(()=>{
        getSubAccount(api).then(result => { setSubAccount(result.subAccountList) })
        getSenderNo().then(result => { setSenderList(result) })
    },[])

    useEffect(()=> {
        if(smsCert){
            getUserNameAndPhoneByEncData(document.querySelector('#certNum').value, document.querySelector('#apiToken').value).then(
                result => {
                    setSmsNumber(result.data.phoneNo);
                    setSmsCheck(true);
                }
            )
        }
    },[smsCert])

    const requestKmcert = () => {
        setSmsCert(false);
        getUserAuthParameter({
            callbackUrl: `${window.location.origin}/mobileCheckResult`,
            type: 'join'
        }).then(result => {
            set_tr_cert(result.tr_cert);
            set_tr_url(result.tr_url);
            set_tr_ver(result.tr_ver);
            openKmcert(reqKMCISForm.current);
        })
    }

    const handleOpenReqModal = () => {setReqModal(true)}
    const handleCloseReqModal = () => {setReqModal(false)}
    const handleOpenSMSModal = () => {setSMSModal(true)}
    const handleCloseSMSModal = () => {setSMSModal(false); setSmsNumber(); setSmsCert(); setReqFiles01(); setReqFiles03(); setReqFiles04();}
    const handleOpenAdminModal = () => {setAdminModal(true)}
    const handleCloseAdminModal = () => {setAdminModal(false); setReqFiles01(); setReqFiles02(); setReqFiles04(); setPhone('');}

    if(subAccount){
        return(
            <>
                <div id="cons-3" className="tab-content current">
                    <div>
                        <div className="tt_h2">발신번호(SMS Calling Number)</div>
                        <div className="tt_script">
                        1. 발신번호 등록은 하단의 발신번호 등록 버튼을 클릭하세요.<br/>
                        2. 등록된 발신번호는 SMS 발송시에 사용할 수 있습니다.
                        </div>
                    </div>
                    <div className="tt_blank20"></div>
                    <div className="pj_box">
                        <div className="r_area">
                            <button className="btn_sm6" onClick={()=>{
                                // if(subAccount.length == 0){alert('등록된 Sub Account가 없습니다.'); return}
                                handleOpenReqModal();
                            }}>+ 발신번호 등록</button>
                        </div>
                    </div>
                    <div className="tt_blank15"></div>
                    <table className="table_mypage">
                        <colgroup>
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>발신번호</th>
                                <th>인증방식</th>
                                <th>요청 일시</th>
                                <th>처리 일시</th>
                                <th>처리상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (()=>{
                                    if(senderList){
                                        const authKind = {S: '휴대폰 본인인증', A: '관리자 인증', R : 'ARS'}
                                        if(senderList.length == 0) return <tr><td colSpan={5}>발신번호 내역이 없습니다.</td></tr>
                                        return senderList.map((el, idx) => {
                                            return(
                                                <tr key={idx}>
                                                    <td>{el.senderNo}</td>
                                                    <td>{authKind[el.authKind]}</td>
                                                    <td>{el.authReqDate[0]}-{el.authReqDate[1]}-{el.authReqDate[2]} {el.authReqDate[3]}:{el.authReqDate[4]}</td>
                                                    <td>
                                                        {
                                                            el.authDate
                                                            ? `${el.authDate[0]}-${el.authDate[1]}-${el.authDate[2]} ${el.authDate[3]}:${el.authDate[4]}`
                                                            : '-'
                                                        }
                                                        
                                                    </td>
                                                    <td>
                                                        {
                                                            el.authYn == 'Y' ? '처리완료' : el.authYn == 'R'? '반려됨' : '승인 대기중'
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                })()
                            }
                        </tbody>
                    </table>
                    
                    <div className="tt_blank80"></div>
                    {/*발신번호 인증 step1-인증방법 선택*/}
                    { reqModal &&
                        <div id="" className="pop_set_p">
                            <div className="pop_section">
                                <div className="parea">
                                    <div className="page">
                                        <div className="ppheader">
                                            <div className="htit">발신번호 인증</div>
                                            <div className="rclose" onClick={handleCloseReqModal}><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                                        </div>
                                        <div className="">
                                            <div className="s_u_p_tit1">안내</div>
                                            <div className="s_u_p_tt2">
                                            전기통신사업법 개정에 따라 홈페이지에서 메시지 전송 시 번호 체계에 맞지 않는 발신 번호로 메시지를 발송할 수 없도록 제한되며 인증받은 발신번호만 사용이 가능합니다.<br/>
                                            <br/>
                                            <strong>발신번호 사전등록 인증 변경 시행 안내</strong><br/>
                                            `거짓으로 표시된 전화번호로 인한 이용자의 피해 예방 등에 관한 고시`변경에 따라 발신번호 인증 방법이 다음과 같이 변경되었습니다. 아래 내용 참조하시어 발신번호 인증 진행하길 바랍니다.
                                            <br/>
                                            <table className="table_mypage">
                                                <colgroup>
                                                    <col width="*" />
                                                    <col width="*" />
                                                    <col width="*" />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>구분</th>
                                                        <th>기존</th>
                                                        <th>변경</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><strong>웹발송</strong></td>
                                                        <td>
                                                            휴대폰인증 (SMS)<br/>
                                                            ARS 인증<br/>
                                                            통신서비스 이용증명원 등
                                                        </td>
                                                        <td>
                                                            휴대폰 본인 인증<br/>
                                                            통신서비스 이용증명원 등
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>사설문자발송장비<br/>(Agent 발송 계정)</strong>
                                                        </td>
                                                        <td>대리인 본인 인증을 통한 등록</td>
                                                        <td>
                                                        휴대폰 본인 인증<br/>
                                                        통신서비스 이용증명원 등
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            - 관련 법령 : 『전기통신사업법』 제 84조의 2
                                            </div>
                                            <div className="p_ct_section">
                                                <div className="ct_tit">발신번호 인증방법을 선택하세요.</div>
                                                <div className="selt_box">
                                                    <span className="sb_btn">
                                                        <div className="s_b_area" onClick={()=>{handleCloseReqModal(); handleOpenSMSModal();}}>
                                                            <span>
                                                                <div>
                                                                    <img src='/images/icon_send_p_img_1.png' alt=""/>
                                                                </div>
                                                                <div className="b_tt">휴대폰 본인 인증</div>
                                                            </span>
                                                        </div>
                                                        <div className="sb_tt">
                                                            SMS 수신이 가능한 번호<br/>예) 010-1234-5678
                                                        </div>
                                                    </span>
                                                    <span className="sb_btn">
                                                        <div className="s_b_area" onClick={()=>{handleCloseReqModal(); handleOpenAdminModal();}}>
                                                            <span>
                                                                <div>
                                                                    <img src='/images/icon_send_p_img_3.png' alt=""/>
                                                                </div>
                                                                <div className="b_tt">관리자 인증</div>
                                                            </span>
                                                        </div>
                                                        <div className="sb_tt">
                                                        수신전용번호<br/>예) 1544-1234
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nt_btn_area_p">
                                            <button className="btn_sm4" onClick={handleCloseReqModal}>취소</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/*발신번호 인증 step2-휴대폰 본인 인증*/}
                    { SMSModal &&
                        <div id="" className="pop_set_p">
                            <div className="pop_section">
                                <div className="parea">
                                    <div className="page">
                                        <div className="ppheader">
                                            <div className="htit">발신번호 인증</div>
                                            <div className="rclose" onClick={handleCloseSMSModal}><img
                                                src='/images/icon_pop_close.png' alt="닫기"/></div>
                                        </div>
                                        <div className="">
                                            <div className="s_u_p_tit1">안내</div>
                                            <div className="s_u_p_tt2">
                                                전기통신사업법 개정에 따라 홈페이지에서 메시지 전송 시 번호 체계에 맞지 않는 발신 번호로 메시지를 발송할 수 없도록 제한되며
                                                인증받은 발신번호만 사용이 가능합니다.<br/>
                                                휴대폰 인증은 계정 명의자와 휴대폰 명의자가 같아야 합니다.<br/>
                                                사업자 등록증과 재직 증명서 상의 회사명이 다를 경우 위임장이 필요합니다.<br/>
                                            </div>
                                            <div className="p_ct_section">
                                                <div className="ct_tit">휴대폰 본인 인증</div>
                                                <div className="ct_ip_section">
                                                    <div className="c_i_tt">1. 인증받을 휴대폰 번호를 입력해주세요.</div>
                                                    <div className="num_ip_box">
                                                        <div className="p_table_section">
                                                            <table className="table_mypageSd">
                                                                <colgroup>
                                                                    <col width="30%"/>
                                                                    <col width="*%"/>
                                                                </colgroup>
                                                                <tbody>
                                                                <tr>
                                                                    <th>등록할 휴대폰 번호:</th>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            style={{
                                                                                width: "calc(90% - 90px)",
                                                                                maxWidth: "200px"
                                                                            }}
                                                                            placeholder="휴대폰 인증시 자동으로 입력됩니다."
                                                                            autoComplete='off'
                                                                            value={smsNumber}
                                                                            readOnly={true}
                                                                            onClick={() => {requestKmcert();}}
                                                                        />
                                                                        <button
                                                                            className="ip_color10"
                                                                            style={{width: "80px"}}
                                                                            onClick={() => {requestKmcert();}}
                                                                        >인증요청
                                                                        </button>
                                                                        <div className="redtt"
                                                                             style={{display: "none"}}>사용할 수 없는 번호입니다.
                                                                            다시 입력해주세요.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="s_u_padding10"></div>
                                                    <div className="c_i_tt">2. 필요 서류를 첨부해주세요.(JPG, PNG, PDF 파일만 첨부가
                                                        가능합니다.)
                                                    </div>
                                                    <div className="num_ip_box">
                                                        <div className="p_table_section">
                                                            <table className="table_mypageSd">
                                                                <colgroup>
                                                                    <col width="30%"/>
                                                                    <col width="*%"/>
                                                                </colgroup>
                                                                <tbody>
                                                                <tr>
                                                                    <th>사업자 등록증(필수):</th>
                                                                    <td>
                                                                        <FileForm setReqFiles={setReqFiles01} idx={1}
                                                                                  type={'businessRegistration '}></FileForm>
                                                                        <div className="redtt"
                                                                             style={{display: "none"}}>첨부할 수 없는 파일형식
                                                                            입니다.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>재직 증명서(필수):</th>
                                                                    <td>
                                                                        <FileForm setReqFiles={setReqFiles03} idx={2}
                                                                                  type={'certificateOfEmployment '}></FileForm>
                                                                        <div className="redtt"
                                                                             style={{display: "none"}}>첨부할 수 없는 파일형식
                                                                            입니다.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>위임장(선택):</th>
                                                                    <td>
                                                                        <FileForm setReqFiles={setReqFiles04} idx={3}
                                                                                  type={'poa '}></FileForm>
                                                                        <div className="redtt"
                                                                             style={{display: "none"}}>첨부할 수 없는 파일형식
                                                                            입니다.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nt_btn_area_p">
                                            <button className="btn_sm4" onClick={handleCloseSMSModal}>취소</button>
                                            <button className="btn_sm6"
                                                    onClick={() => {
                                                        if (!reqFiles01) {
                                                            alert('사업자등록증을 업로드해주세요.');
                                                            return
                                                        }
                                                        if (!reqFiles03) {
                                                            alert('재직증명서를 업로드해주세요.');
                                                            return
                                                        }
                                                        if (!smsCheck) {
                                                            alert('인증번호를 확인해주세요.');
                                                            return
                                                        }

                                                        reqSMSAdminCertify(smsNumber, reqFiles01, reqFiles03, reqFiles04).then(result => {
                                                            if (result == 500) {
                                                                alert('server error : 500')
                                                            }
                                                            if (result && result.data.data === 'ok') {
                                                                setSmsCheck(false)
                                                                setSMSModal(false)
                                                                alert('발신번호 신청이 완료되었습니다.')
                                                                getSenderNo().then(result => {
                                                                    setSenderList([...result])
                                                                })
                                                                handleCloseAdminModal()
                                                            } else {
                                                                alert(result.data.data)
                                                            }
                                                        })
                                                    }
                                                    }
                                            >등록하기
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form name="reqKMCISForm" method="post" action="#" ref={reqKMCISForm}>
                                <input type="hidden" name="tr_cert" value={tr_cert}/>
                                <input type="hidden" name="tr_url" value={tr_url}/>
                                <input type="hidden" name="tr_ver" value={tr_ver}/>
                            </form>
                            <form name="resKMCISForm" method="post" action="#" onClick={() => {

                            }}>
                                <input type="hidden" id="apiToken" onClick={()=>{setSmsCert(true);}}/>
                                <input type="hidden" id="certNum"/>
                            </form>
                        </div>
                    }
                    {/*발신번호 인증 step2-관리자 인증*/}
                    { adminModal &&
                        <div id="" className="pop_set_p">
                            <div className="pop_section">
                                <div className="parea">
                                    <div className="page">
                                        <div className="ppheader">
                                            <div className="htit">발신번호 인증</div>
                                            <div className="rclose" onClick={handleCloseAdminModal}><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                                        </div>
                                        <div className="">
                                            <div className="s_u_p_tit1">안내</div>
                                            <div className="s_u_p_tt2">
                                            전기통신사업법 개정에 따라 홈페이지에서 메시지 전송 시 번호 체계에 맞지 않는 발신 번호로 메시지를 발송할 수 없도록 제한되며 인증받은 발신번호만 사용이 가능합니다.
                                            </div>
                                            <div className="p_ct_section">
                                                <div className="ct_tit">관리자 인증</div>
                                                <div className="ct_text">수신전용 번호의 경우 관리자 승인 후 발신번호 인증이 진행됩니다.<br/><b>사업자등록증</b>과 <b>통신서비스 이용증명원</b>을 첨부해 주세요.</div>
                                                <div className="ct_ip_section">
                                                    <div className="c_i_tt">1. 사업자등록증과 통신서비스 이용증명원을 첨부해주세요.(JPG, PNG, PDF 파일만 첨부가 가능합니다.)</div>
                                                    <div className="num_ip_box">
                                                        <div className="p_table_section">
                                                            <table className="table_mypageSd">
                                                                <colgroup>
                                                                    <col width="30%" />
                                                                    <col width="*%" />
                                                                </colgroup>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>사업자등록증:</th>
                                                                        <td>
                                                                            <FileForm setReqFiles={setReqFiles01} idx={1} type={'businessRegistration '}></FileForm>
                                                                            <div className="redtt" style={{display:"none"}}>첨부할 수 없는 파일형식 입니다.</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>통신서비스 이용증명원:</th>
                                                                        <td>
                                                                            <FileForm setReqFiles={setReqFiles02} idx={2} type={'serviceUseCertificate '}></FileForm>
                                                                            <div className="redtt" style={{display:"none"}}>첨부할 수 없는 파일형식 입니다.</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>발신번호 위임장(선택):</th>
                                                                        <td>
                                                                            <FileForm setReqFiles={setReqFiles04} idx={4} type={'poa '}></FileForm>
                                                                            <div className="redtt" style={{display:"none"}}>첨부할 수 없는 파일형식 입니다.</div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="s_u_padding10"></div>
                                                    <div className="c_i_tt">2. 인증받을 유선 번호를 입력해주세요.</div>
                                                    <div className="num_ip_box">
                                                        <div className="p_table_section">
                                                            <table className="table_mypageSd">
                                                                <colgroup>
                                                                    <col width="30%" />
                                                                    <col width="*%" />
                                                                </colgroup>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>등록할 수신전용 번호:</th>
                                                                        <td>
                                                                            <input type="text" style={{width:"calc(90% - 90px)",maxWidth:"200px"}} placeholder="' - ' 를 제외하고 입력해주세요." value={phone} onChange={(e)=>{
                                                                                let inputVal = e.target.value.replace(/[^0-9]/g, '');
                                                                                setPhone(inputVal)
                                                                            }} />
                                                                            {/* <button className="ip_color10" style={{width:"80px"}}>등록</button> */}
                                                                            <div className="redtt"  style={{display:"none"}}>사용할 수 없는 번호입니다. 다시 입력해주세요.</div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nt_btn_area_p">
                                            <button className="btn_sm4" onClick={handleCloseAdminModal}>취소</button>
                                            <button className="btn_sm6"
                                                onClick={()=>{
                                                    if(!reqFiles01){alert('사업자등록증을 업로드해주세요.'); return}
                                                    if(!reqFiles02){alert('통신서비스 이용증명원을 업로드해주세요.'); return}
                                                    if(!phone){alert('등록할 수신전용 번호를 입력해주세요.'); return}
                                                    if(!checkAdminCheckCallNumber(phone)){alert('올바른 수신전용 번호를 입력해주세요.'); return}
                                                    reqAdminCertify(phone, reqFiles01, reqFiles02, reqFiles04).then(result => {
                                                        // console.log(result == 500)
                                                        if(result == 500) {
                                                            alert('server error : 500')
                                                        }

                                                        if(result && result.data.data === 'ok'){
                                                            alert('정상적으로 신청되었습니다.')
                                                            getSenderNo().then(result => {setSenderList([...result])})
                                                            handleCloseAdminModal()
                                                        }else{
                                                            alert(result.data.data)
                                                        }
                                                    })
                                                }}
                                            >등록하기</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }else return <Loading />
}

export default ServiceConsoleTab03;

const FileForm = (props) => {
    const {setReqFiles,idx, type} = props;
    const [files, setFiles] = useState();
    const [viewFiles, setViewFiles] = useState();

    useEffect(()=>{
        files && setReqFiles(files.getAll(type))
    },[files])

    return(
        <>
            <input
                type="file"
                style={{display:'none'}}
                id={`addFile_${idx}`}
                name="addFile"
                multiple="multiple"
                onClick={(e)=>{ e.target.value = '' }}
                onChange={(e)=>{
                    let fileData = new FormData();
                    for(const element of e.target.files){
                        fileData.append(type, element , element.name);
                    }
                    setFiles(fileData)
                    setViewFiles(fileData.getAll(type))
                }}
            />
            <button className="ip_color10" style={{width:"120px"}} onClick={()=>{document.querySelector(`#addFile_${idx}`).click();}}>파일찾기</button>
            {
                viewFiles && viewFiles.length !== 0 &&
                viewFiles.map((file,idx)=>{
                    return <FileItem key={idx} idx={idx} files={viewFiles} setFiles={setViewFiles}>{file.name}</FileItem>
                })
            }
        </>
    )
}

const FileItem = (props) => {
    const {children, files, setFiles, idx} = props;
    return(
        <span className={`file_name file ${idx}`}>
            {children}
            <button>
                <img
                    style={{verticalAlign: 'baseline'}}
                    src={`/images/icon_f_close.png`}
                    alt="첨부파일 삭제"
                    onClick={()=>{
                        if(files.length == 1){setFiles(); return}
                        setFiles([...files.slice(0,idx), ...files.slice(idx+1, files.length)])
                    }}
                />
            </button>
        </span>
    )
}